<template>
  <div class="pageContol">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">题库管理</a>
          <i>></i>
          <a href="javascript:;" @click="init" class="cur-a">用户纠错</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="operationControl flexdc" style="align-items:flex-start">
          <div class="searchbox" style="margin-bottom:15px">
            <div title="题库名称" class="searchboxItem ci-full">
              <span class="itemLabel">题库名称:</span>
              <el-input
                v-model="questionBankName"
                type="text"
                size="small"
                placeholder="请输入题库名称"
                clearable
              />
            </div>
            <div title="行政区划" class="searchboxItem ci-full">
              <span class="itemLabel">行政区划:</span>
              <el-cascader
                v-model="areaId"
                :options="areatreeList"
                :props="propsarea"
                :disabled="disabled"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </div>
            <span title="题库类别" class="searchboxItem ci-full flexcc">
              <span class="itemLabel">题库类别:</span>
              <el-cascader
                v-model="categoryCode"
                :options="TreeList"
                :props="propsTree"
                clearable
                filterable
                size="small"
              ></el-cascader>
            </span>
            <div title="错误题目内容" class="searchboxItem ci-full">
              <span class="itemLabel" style="min-width:7rem">错误题目内容:</span>
                <el-input
                v-model="questionTitle"
                type="text"
                size="small"
                placeholder="请输入错误题目内容"
                clearable
              />
            </div>
          </div>
          <div class="searchbox">
             <div title="处理人" class="searchboxItem ci-full">
              <span class="itemLabel">处理人:</span>
              <el-input
                v-model="handleUserName"
                type="text"
                size="small"
                placeholder="请输入处理人"
                clearable
              />
            </div>
            <div title="状态" class="searchboxItem ci-full">
              <span class="itemLabel">状态:</span>
              <el-cascader
                  v-model="correctState"
                  :options="correctStateList"
                  :props="propsCorrectState"
                  :disabled="disabled"
                  clearable
                  filterable
                  size="small"
              ></el-cascader>
            </div>
            <div title="处理时间" class="searchboxItem ci-full">
              <span class="itemLabel">处理时间:</span>
               <el-date-picker
                  size="small"
                  v-model="handleUserTime"
                  type="datetimerange"
                  range-separator="至"
                  start-placeholder="开始时间"
                  end-placeholder="结束时间"
                  format="yyyy-MM-dd HH:mm:ss"
                  value-format="yyyy-MM-dd HH:mm:ss"
                  :default-time="['00:00:00', '23:59:59']"
                >
                </el-date-picker>
            </div>
            
            <el-button
              class="bgc-bv"
              style="margin:0 10px"
              round
              @click="getData()"
              >查询</el-button
            >
          </div>
        </div>
        <div class="framePage-scroll">
          <div class="ovy-a">
            <el-table
              ref="multipleTable"
              :data="tableData"
              :height="tableHeight"
              size="small"
              tooltip-effect="dark"
              style="width: 100%"
              :header-cell-style="tableHeader"
              stripe
            >
              <el-table-column
                label="序号"
                align="center"
                type="index"
                :index="indexMethod"
                fixed
                width="80px"
              />
              <el-table-column
                label="题库名称"
                align="left"
                prop="questionBankName"
                show-overflow-tooltip
                width="280"
                fixed
              />
              <el-table-column
                label="行政区划"
                align="left"
                show-overflow-tooltip
                prop="areaName"
                width="100"
              />
              <el-table-column
                label="题库类别"
                align="left"
                min-width="220"
                show-overflow-tooltip
              >
                <template slot-scope="scope">{{
                  scope.row.questionCategory || "--"
                }}</template>
              </el-table-column>
              <el-table-column
                label="错误题目类型"
                align="left"
                prop="questionType"
                width="100"
                show-overflow-tooltip
              >
                <template slot-scope="scope">
                  {{  $setDictionary("QUESTIONTYPE", scope.row.questionType) || 0 }}

                </template>
              </el-table-column>
              <el-table-column
                label="错误题目内容"
                align="left"
                 min-width='300'
              >
                <template slot-scope="scope" >
                  <span class="tablequestionTitle" v-html="scope.row.questionTitle"></span>
                </template>
              </el-table-column>
              <el-table-column
                label="纠错描述"
                align="left"
                prop="correctDescribe"
                min-width='300'
              >
                <template slot-scope="scope">
                  {{ scope.row.correctDescribe }}
                </template>
              </el-table-column>
              <el-table-column
                label="用户姓名"
                align="left"
                prop="userName"
              >
                <template slot-scope="scope">
                  {{ scope.row.userName }}
                </template>
              </el-table-column>
              <el-table-column
                label="用户手机号"
                align="left"
                prop="mobile"
                 min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.mobile }}
                </template>
              </el-table-column>
              <el-table-column
                  label="状态"
                  align="left"
                  prop="mobile"
              >
                <template slot-scope="scope">
                  {{ scope.row.correctState=="10"?"未处理":"已处理" }}
                </template>
              </el-table-column>
              <el-table-column
                label="提交时间"
                align="left"
                prop="createTime"
                min-width="150"
              >
                <template slot-scope="scope">
                  {{ scope.row.createTime }}
                </template>
              </el-table-column>
              <el-table-column
                label="处理人"
                align="left"
                prop="handleUserName"
                min-width="150"
              />
              <el-table-column
                label="处理时间"
                align="left"
                prop="updateTime"
                min-width="150"
              />
              <el-table-column
                label="操作"
                fixed="right"
                align="center"
                width="80px"
              >
                <div slot-scope="scope" class="flexcc">
                  <el-button
                    type="text"
                    style="padding:0px 5px"
                    size="mini"
                    @click="
                      handlEdit(
                        scope.row.questionId,
                        scope.row.correctId,
                      )
                    "
                    >编辑</el-button
                  >
                </div>
              </el-table-column>
              <Empty slot="empty" />
            </el-table>
          </div>
        </div>
        <PageNum
          :apiData="apiData"
          @sizeChange="sizeChange"
          @getData="getData"
        />
      </div>
    </div>
    <UserErrorEdit
      ref="UserErrorEdit"
      @getnewdatap="getData(-1)"
      @subShows="subShows"
    />
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
// import treePopupQuestion from "@/components/treePopupQuestion";
import UserErrorEdit from "@/views/operate/UserErrorCorrection/UserErrorEdit.vue";
import { resetKeepAlive } from "@/utils/common";
export default {
  name: "questionbankList",
  components: {
    Empty,
    PageNum,
    // treePopupQuestion,
    UserErrorEdit
  },
  mixins: [List],
  data() {
    return {
      questionBankName: "", //题库名称
      questionTitle: "", //题库名称
      handleUserName:"",//处理人
      handleUserTime:[],//处理时间
      params: {},
      ruleForm: {
        Trainingtype: "",
      },
      areatreeList: [],
      correctStateList: [{value: "10", label: "未处理"},{value: "20", label: "已处理"}],
      areaId: "",
      correctState:"",
      propsarea: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
      propsCorrectState: {
        value: "value",
        label: "label",
        emitPath: false,
      },
       categoryCode:'',
      TreeList: [],
propsTree: {
        value: "value",
        label: "label",
        emitPath: false,
        checkStrictly: true,
      },
    };
  },
  created() {
    this.getareatree();
    this.getTree();
  },
  computed: {},
  methods: {
    /* tree */
    childBack(params) {
      this.params = { ...params };
    },
    clearParams() {
      this.params = {};
    },
    /* 行政区划 */
    getareatree() {
      this.$post("/sys/area/tree?areaLevel=1", {}, 3000, true, 2).then((ret) => {
        this.areatreeList = ret.data;
      });
    },
    /* 题库类别 */
    getTree() {
      this.$post('/miniapp/category/enable/tree',{disabledLevels:'1,2,3'},3000, true, 2).then(ret => {
        this.TreeList = ret.data
      })
    },
    /* 获取列表数据 */
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
      };
      if (this.areaId) {
        params.areaId = this.areaId;
      }
      if (this.questionBankName) {
        params.questionBankName = this.questionBankName;
      }
      if (this.correctState) {
        params.correctState = this.correctState;
      }
      if (this.questionTitle) {
        params.questionTitle = this.questionTitle;
      }
      if (this.categoryCode) {
        params.categoryCode = this.categoryCode;
      }
      if (this.handleUserName) {
        params.handleUserName = this.handleUserName;
      }
      if (this.handleUserTime&&this.handleUserTime.length==2) {
        params.handleStartTime = this.handleUserTime[0];
        params.handleEndTime = this.handleUserTime[1];
      }
      this.doFetch(
        {
          url: "/question/correct/page",
          params,
          pageNum,
        },
        true,
        2
      );
    },
    /* 编辑 */
    handlEdit(questionId,correctId) {
         this.$refs.UserErrorEdit.showPopUp(questionId,correctId);

    },
    
  },
  beforeRouteLeave: resetKeepAlive,
  watch: {
    $route: {
      handler: function(val, oldVal) {
        if (val.query.refresh) {
          this.getData(-1);
        } else {
           this.getData();
        }
        // if (oldVal.query.stu == "add") {
        //   (this.recommendName = ""),
        //     (this.areaId = ""),
        //     (this.terminals = ""),
        //     (this.pageNum = 1);
        //   this.getData(-1);
        // }
      },
      // 深度观察监听
      deep: true,
    },
  },
};
</script>
<style lang="less" >
.el-tooltip__popper {
  max-width: 40% !important;
}
.tablequestionTitle {
  img {
    width: 3rem;
  }
}
</style>
